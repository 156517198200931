<template>
    <el-main>
        <el-form class="el-form-search" label-width="120px">
            <el-form-item label="订单号：">
                <el-input size="small" v-model="searchForm.order_sn"></el-input>
            </el-form-item>
            <el-form-item label="NFC编号：">
                <el-input size="small" v-model="searchForm.tag"></el-input>
            </el-form-item>
            <el-form-item label="产生日期：">
                <el-date-picker size="small" v-model="searchForm.time" type="daterange" range-separator="至"
                    value-format="timestamp" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
            </el-form-item>
            <el-form-item label="店铺名称：">
                <el-input size="small" v-model="searchForm.store_name"></el-input>
            </el-form-item>
            <el-form-item label="服务商姓名：">
                <el-input size="small" v-model="searchForm.fname"></el-input>
            </el-form-item>
            <el-form-item label="服务商电话：">
                <el-input size="small" v-model="searchForm.fmobile"></el-input>
            </el-form-item>
            <el-form-item label=" " label-width="25px">
                <el-button type="primary" size="small" @click="getDataList(1)">搜索</el-button>
                <el-button type="text" size="small" @click="clearSearch">清空搜索条件</el-button>
            </el-form-item>
        </el-form>
        <div class="total-num">
            <div>筛选后总预估收益：{{ total_amount || '0.00' }}</div>
        </div>
        <el-table :data="dataList" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
            <template slot="empty">
                <No />
            </template>
            <el-table-column prop="tag" label="NFC编号" min-width="150" align="center"></el-table-column>
            <el-table-column prop="fname" label="所属服务商姓名" min-width="200" align="center"></el-table-column>
            <el-table-column prop="fmobile" label="服务商电话" min-width="130" align="center"></el-table-column>
            <el-table-column prop="store_name" label="店铺名称" align="center" min-width="200"></el-table-column>
            <el-table-column prop="user_id" label="用户ID" min-width="110" align="center"></el-table-column>
            <el-table-column prop="times" label="充电时间（小时）" min-width="200" align="center"></el-table-column>
            <el-table-column label="订单金额" align="center" min-width="180">
                <template v-slot="{ row }">
                    ￥{{ row.price }}
                </template>
            </el-table-column>
            <el-table-column prop="order_sn" label="订单编号" align="center" min-width="200"></el-table-column>
            <el-table-column label="产生时间" align="center" min-width="180">
                <template v-slot="{ row }">
                    {{ getDateformat(row.create_time) }}
                </template>
            </el-table-column>
        </el-table>
        <Paging :total="total" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData"></Paging>
    </el-main>
</template>

<script>
import Paging from '@/components/paging.vue';
import { getDateformat } from '@/util/getDate.js'

export default {
    components: {
        Paging
    },
    data () {
        return {
            getDateformat,
            searchForm: {
                page: 1,
                rows: 10,
                order_sn: '',
                tag: '',
                store_name: '',
                time: '',
                fname: '',
                fmobile: ''
            },
            dataList: [],
            total: 0,
            total_amount: '0.00'
        }
    },
    created () {
        if (this.$route.query.store_name) this.searchForm.store_name = this.$route.query.store_name
        this.getDataList()
    },
    methods: {
        updateData (val, status) {
            if (status == 0) {
                this.searchForm.rows = val;
            } else {
                this.searchForm.page = val;
            }
            this.getDataList()
        },
        clearSearch () {
            this.searchForm = {
                page: 1,
                rows: 10,
                order_sn: '',
                tag: '',
                store_name: '',
                time: '',
                fname: '',
                fmobile: ''
            }
            this.getDataList(1)
        },
        getDataList (style) {
            if (style == 1) this.searchForm.page = 1

            let obj = { ... this.searchForm }
            if (obj.time) {
                obj.start_time = obj.time[0] / 1000
                obj.end_time = obj.time[1] / 1000
            }
            delete obj.time
            this.$axios.post(this.$api.serviceProvider.ChargeOrderList, obj)
                .then(res => {
                    if (res.code === 0) {
                        this.dataList = res.result.list
                        this.total = res.result.total
                        this.total_amount = res.result.total_amount
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
        },
    }
}
</script>

<style lang="less" scoped>
.el-main {
    background-color: #fff;
}

.total-num {
    display: flex;
    font-weight: bold;
    margin: 20px 0;

    div {
        margin-right: 40px;
    }
}
</style>